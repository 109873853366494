import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UpdateDateOfOccurrenceService {
  constructor(private httpClient: HttpClient) {
  }

  updateDateOfOccurrence(claimId: number, dateOfOccurrence: Date) {
    return this.httpClient.put(`${environment.apiServer}/UpdateDateOfOccurrence`, {claimId, dateOfOccurrenceString: dateOfOccurrence.toISOString().split('T')[0]});
  }
}
