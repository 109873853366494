<ng-container *ngIf="claimsPortalClaimDuplicatesOnVehicleFromSameRepairCentreWithinTimeFrame.length">
  <div class="alert alert-danger alert-outline-coloured alert-dismissible mb-5">
    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
    <div class="alert-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
      </svg>
    </div>
    <div class="alert-message pe-5">
      <strong>Multiple claims found.</strong>
      <span *ngIf="(claimsPortalClaimDuplicatesOnVehicleFromSameRepairCentreWithinTimeFrame.length === 1)"> There is another claim on this vehicle submitted within the past 7 days.</span>
      <span *ngIf="(claimsPortalClaimDuplicatesOnVehicleFromSameRepairCentreWithinTimeFrame.length > 1)"> There are multiple claims on this vehicle submitted within the past 7 days.</span>
      <div>
        <ng-container *ngFor="let claim of claimsPortalClaimDuplicatesOnVehicleFromSameRepairCentreWithinTimeFrame">
          <ul class="d-inline-block my-0 ms-0 me-3 p-0"><li><a href="/claim/{{claim.claimId}}">Claim {{claim.claimId}}</a></li></ul>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
