<div class="modal-header">
  <h4 class="modal-title ps-3">Start a New Claim</h4>
  <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close" (click)="this.activeModal.close()"></button>
</div>

<div class="modal-body mt-3 mx-3 mb-0">
  <div class="row mb-3">
    <div class="col-sm-4 mb-2 mb-sm-0">
      <input
        class="form-control"
        type="text"
        placeholder="VIN"
        [disabled]="!!warrantyIdSearchTerm"
        minlength="8"
        [(ngModel)]="vinSearchTerm"
        autocomplete="off"
      >
    </div>
    <div class="col-sm-1 text-center pt-1 mb-3 mb-sm-0">
      <span class="badge rounded-pill bg-secondary">OR</span>
    </div>
    <div class="col-sm-4 mb-3 mb-sm-0">
      <input
        class="form-control"
        type="text"
        placeholder="Policy Number"
        [disabled]="!!vinSearchTerm"
        [(ngModel)]="warrantyIdSearchTerm"
        autocomplete="off"
      >
    </div>
    <div class="col-sm-3 mb-3 mb-sm-0">
      <button id="vinPolicyLookupButton" class="btn btn-secondary position-relative w-100"
              [disabled]="!(!!vinSearchTerm && vinSearchTerm.length > 7 || !!warrantyIdSearchTerm)" (click)="search()">
        <span
          class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
          role="status" aria-hidden="true" [ngClass]="{'show': busySearching}"></span>
        <span class="text fade" [ngClass]="{'show': !busySearching}">Lookup</span>
      </button>
    </div>
  </div>
  <hr class="my-4" *ngIf="hasSearchButtonBeenClicked && !busySearching">
  <p *ngIf="hasSearchButtonBeenClicked && !vehicles.length && !busySearching">No matching vehicles found.</p>
  <div id="vinPolicyResults" *ngIf="vehicles.length">
    <div class="row">
      <div class="col-12 text-center mb-3">
        <h3 class="text-info m-0">{{vehicles[0].year}} {{vehicles[0].make}} {{vehicles[0].model}} {{vehicles[0].trim}}</h3>
        <p class="text-uppercase fs-4 my-0">{{vehicles[0].vin}}</p>
      </div>
        <ng-container *ngFor="let warranty of warranties">
          <div class="col-sm-6 d-flex flex-column mb-4">
            <div class="bg-light rounded-top text-center flex-fill pt-3 px-3">
              <h3 class="m-0">{{warranty.coverageType}}</h3>
              <p class="text-uppercase fs-4 mt-0">{{warranty.terseLabel}}</p>
              <div class="badge badge-lg rounded-pill mb-1" [ngClass]="{'bg-danger': warranty.status === 'Expired', 'bg-success': warranty.status === 'Active', 'bg-primary': warranty.status !== 'Expired' && warranty.status !== 'Active'}">{{warranty.status}}</div>
              <p class="m-0">Expiry: {{warranty.expiryDate | date:'longDate' }}</p>
              <p class="mt-0">Deductible: ${{warranty.deductible ?? 0 }}</p>
            </div>
            <div class="bg-light rounded-bottom text-center pb-4">
              <button type="button" class="btn btn-primary" (click)="createClaim(warranty)">Start Claim</button>
            </div>
          </div>
        </ng-container>
    </div>
  </div>
</div>

<ng-template [ngIf]="loading">Loading...</ng-template>


