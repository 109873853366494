import {Component, OnInit, Renderer2, AfterViewChecked, ElementRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as feather from "feather-icons";
import AjaxSettings = DataTables.AjaxSettings;
import {ListClaimsService} from "./services/list-claims.service";
import {DataTableDirective} from "angular-datatables";
import * as bootstrap from "bootstrap";

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['./claims-list.component.scss']
})
export class ClaimsListComponent implements OnInit, AfterViewChecked {
  @ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
  @ViewChild('claimsDataTable', { static: false }) claimsDataTable!: ElementRef;
  dtOptions: DataTables.Settings = {};
  errorOccurred = false;

  constructor(
    private listClaimsService: ListClaimsService,
    private renderer: Renderer2,
  ) {
    this.renderer.addClass(document.body, 'adjuster');
    this.renderer.addClass(document.body, 'claim-list');
    this.renderer.removeClass(document.body, 'claim');
  }

  ngOnInit(): void {
    this.dtOptions = {
      dom: '<"row"<"col-md-6"i><"col-md-6"f>><"row"<"px-0"t>><"row"<"col-6 mt-2"l><"col-6"p>>',
      language: {
        info: 'Showing _START_ to _END_ of _TOTAL_ claims',
        infoEmpty: 'Showing 0 claims',
        infoFiltered: '<br>Filtered from _MAX_ total claims',
        zeroRecords: 'No matching claims found',
        lengthMenu: 'Show _MENU_ claims',
        loadingRecords: '<div class="d-flex center" style="width: 100%"><div class="spinner-border text-primary" style="margin: auto" role="status"><span class="sr-only">Loading...</span></div></div>',
      },
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, 'All']
      ],
      pageLength: 25,
      order: [[2, 'desc']],
      processing: true,
      stateSave: false,
      ajax: (ajaxSettings: AjaxSettings, callback) => {
        this.listClaimsService.listClaims
        (
          ajaxSettings, {}
        ).subscribe({
            next: (resp: any) => {
              callback({
                  recordsTotal: resp.length,
                  recordsFiltered: resp.length,
                  data: resp
                }
              )

            },
            error: () => {
              this.errorOccurred = true;
            }
          }
        );
      },
      columns: [
        {
          title: '',
          className: 'text-nowrap align-middle py-0 ps-3 pe-0',
          searchable: false,
          orderable: false,
          data: null,
          render: (data) => {
            if (
              data.claimsPortalClaimDuplicatesOnVehicleWithinTimeFrame.length
              || data.claimsPortalClaimDuplicatesOnWarrantyWithinTimeFrame.length
              || data.e2DuplicatesOnWarrantyWithinTimeFrame.length
              || data.e2DuplicatesOnVehicleWithinTimeFrame.length
            ) {
              return `
                <span class="text-danger" ngbTooltip="VIN found in another claim within 7 days">
                  <svg class="feather" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M5.75 1C6.16421 1 6.5 1.33579 6.5 1.75V3.6L8.22067 3.25587C9.8712 2.92576 11.5821 3.08284 13.1449 3.70797L13.3486 3.78943C14.9097 4.41389 16.628 4.53051 18.2592 4.1227C19.0165 3.93339 19.75 4.50613 19.75 5.28669V12.6537C19.75 13.298 19.3115 13.8596 18.6864 14.0159L18.472 14.0695C16.7024 14.5119 14.8385 14.3854 13.1449 13.708C11.5821 13.0828 9.8712 12.9258 8.22067 13.2559L6.5 13.6V21.75C6.5 22.1642 6.16421 22.5 5.75 22.5C5.33579 22.5 5 22.1642 5 21.75V1.75C5 1.33579 5.33579 1 5.75 1Z" />
                  </svg>
                </span>
              `;
            }
            return '';
          }
        }, {
          title: '',
          data: 'isNoTimelyResponseFromRepairCentre',
          className: 'text-nowrap align-middle p-0',
          searchable: false,
          orderable: false,
          render: (data) => {
            if (data) {
              return `
                <span class="text-warning" ngbTooltip="No reply within 48 hours">
                  <svg class="feather" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M23.5,18.7L14.1,2.8c-1-1.6-3.3-1.6-4.3,0L.5,18.7c-1,1.7.3,3.7,2.1,3.7h18.8c1.9,0,3.1-2,2.1-3.7ZM11.1,6.6h1.9c.6,0,1,.5,1,1l-.4,6.9c0,.5-.5.9-1,.9h-1.1c-.5,0-1-.4-1-.9l-.4-6.9c0-.6.4-1,1-1ZM12,20.4c-1.1,0-2-.9-2-2s.9-2,2-2,2,.9,2,2-.9,2-2,2Z"/>
                  </svg>
                </span>
              `;
            }
            return '';
          }
        }, {
          title: 'Date',
          data: 'submittedDateTime',
          className: 'text-nowrap w-auto ps-3',
          render: (data, type) => {
            if (type === 'sort') {
              return data;
            } else {
              const date = new Date(data);
              const dateStr = date.toLocaleDateString("en-us", {weekday: "short", year: "numeric", month: "short", day: "numeric"});
              const timeStr = date.toLocaleTimeString("en-us", {hour12: true, hour: "numeric", minute: "2-digit"})
              return `<span class="text-nowrap">${dateStr}</span> <span class="text-nowrap">${timeStr}</span>`;
            }
          }
        },
        {
          title: 'Type',
          data: 'coverageType',
          className: 'text-nowrap',
          render: (data) => {
            return data;
          }
        }, {
          title: '<span class="text-nowrap">Repair Center</span>',
          data: 'repairCentreName',
          className: 'w-auto',
          render: (data) => {
            return data;
          }
        }, {
          title: 'User',
          data: 'submittedBy',
          className: 'w-auto',
          render: (data) => {
            return data.firstName + ' ' + data.lastName;
          }
        }, {
          title: 'Customer',
          data: 'customerName',
          className: 'w-auto'
        }, {
          title: 'VIN',
          data: 'vin'
        }, {
          title: 'Vehicle',
          data: 'ymmt',
          className: 'w-auto'
        }, {
          title: 'Status',
          data: 'status',
          render: (data) => {
            let statusClass = '';
            switch (data.toLowerCase()) {
              case 'draft':
                statusClass = 'secondary';
                break;
              case 'submitted':
                statusClass = 'primary';
                break;
              case 'viewed':
                data = 'Submitted'
                statusClass = 'primary';
                break;
              case 'pre-approved':
              case 'accepted':
              case 'approved':
                statusClass = 'success';
                break;
              case 'rejected':
                statusClass = 'danger';
                break;
              case 'deleted':
                statusClass = 'black';
                break;
              default:
                statusClass = 'primary';
            }
            return `<span class="badge bg-${statusClass}">${data}</span>`;
          }
        }, {
          title: 'Adjuster',
          data: 'assignedToName',
          render: (data) => {
            return data;
          }
        }, {
          title: '',
          data: '',
          searchable: false,
          orderable: false,
          className: 'text-end text-nowrap align-middle py-0 pe-3',
          render: (data, type, row) => {
            return `<a class="btn btn-link border-0 p-0 ms-2" href="/adjuster/claim/${row.claimId}" ngbTooltip="Edit"><i data-feather="edit-2"></i></a>`;
          }
        }
      ],
      drawCallback: function () {
        feather.replace();
      }
    };

  }

  ngAfterViewChecked(): void {
    this.initializeTooltips();
  }

  initializeTooltips(): void {
    const elements = this.claimsDataTable.nativeElement.querySelectorAll('[ngbTooltip]');
    elements.forEach((element: HTMLElement) => {
      if (!element.hasAttribute('tooltip-initialized')) {
        const tooltipText = element.getAttribute('ngbTooltip');
        this.renderer.setAttribute(element, 'title', tooltipText!);
        new bootstrap.Tooltip(element, { offset: [0, 0]});
        this.renderer.setAttribute(element, 'tooltip-initialized', '');
      }
    });
  }

}
