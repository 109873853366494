<ng-container *ngIf="(claimsPortalDuplicateWarrantyClaims && claimsPortalDuplicateWarrantyClaims.length)">
  <div class="col">
    <div class="alert alert-danger alert-outline-coloured alert-dismissible">
      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
      <div class="alert-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
      </div>
      <div class="alert-message pe-5">
        <strong>Multiple claims found.</strong>
        <span *ngIf="(claimsPortalDuplicateWarrantyClaims.length === 1)"> There is another claim in the Online Claims portal for this VIN and Policy submitted within the past 7 days.</span>
        <span *ngIf="(claimsPortalDuplicateWarrantyClaims.length > 1)"> There are multiple claims in the Online Claims portal for this VIN and Policy submitted within the past 7 days.</span>
        <div>
          <ng-container *ngFor="let claim of claimsPortalDuplicateWarrantyClaims">
            <ul class="d-inline-block my-0 ms-0 me-3 p-0"><li><a href="/adjuster/claim/{{claim.claimId}}">Claim {{claim.claimId}}</a></li></ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(claimsPortalDuplicateVehicleClaims && claimsPortalDuplicateVehicleClaims.length)">
  <div class="col">
    <div class="alert alert-warning alert-outline-coloured alert-dismissible">
      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
      <div class="alert-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3"></path>
          <path d="M12 9v4"></path>
          <path d="M12 17h.01"></path>
        </svg>
      </div>
      <div class="alert-message pe-5">
        <strong>Multiple claims found.</strong>
        <span *ngIf="(claimsPortalDuplicateVehicleClaims.length === 1)"> There is another claim in the Online Claims portal for this VIN but for a {{claimsPortalDuplicateVehicleClaims[0].coverageTypeLabel}} submitted within the past 7 days.</span>
        <span *ngIf="(claimsPortalDuplicateVehicleClaims.length > 1)"> There are multiple claims in the Online Claims portal for this VIN submitted within the past 7 days.</span>
        <div>
          <ng-container *ngFor="let claim of claimsPortalDuplicateVehicleClaims">
            <ul class="d-inline-block my-0 ms-0 me-3 p-0"><li><a [routerLink]="['/adjuster/claim', claim.claimId]">{{claim.coverageTypeLabel}} Claim {{claim.claimId}}</a></li></ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(e2DuplicateWarrantyClaims && e2DuplicateWarrantyClaims.length)">
  <div class="col">
    <div class="alert alert-danger alert-outline-coloured alert-dismissible">
      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
      <div class="alert-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
      </div>
      <div class="alert-message pe-5">
        <strong>Multiple claims found. </strong>
        <span *ngIf="(e2DuplicateWarrantyClaims.length === 1)">
          There is another claim in E2 for this VIN and Policy submitted within the past 7 days.
        </span>
        <span *ngIf="(e2DuplicateWarrantyClaims.length > 1)"> There are multiple claims in E2 for this VIN and Policy submitted within the past 7 days.</span>
        <div>
          <ng-container *ngFor="let claim of e2DuplicateWarrantyClaims">
            <ul class="d-inline-block lh-md my-0 ms-0 me-3 p-0">
              <li>
                <a href="https://e2.eazeetrak.com/claims/{{claim.enterpriseId}}/detail" target="_blank">E2
                  Claim {{claim.enterpriseId}}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link ms-1" _ngcontent-ng-c4060576122=""><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                </a>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(e2DuplicateVehicleClaims && e2DuplicateVehicleClaims.length)">
  <div class="alert alert-warning alert-outline-coloured alert-dismissible">
    <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
    <div class="alert-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3"></path>
        <path d="M12 9v4"></path>
        <path d="M12 17h.01"></path>
      </svg>
    </div>
    <div class="alert-message pe-5">
      <strong>Multiple claims found.</strong>
      <span *ngIf="(e2DuplicateVehicleClaims.length === 1)"> There is another claim in the Online Claims portal for this VIN but for a {{e2DuplicateVehicleClaims[0].coverageTypeLabel}} submitted within the past 7 days.</span>
      <span *ngIf="(e2DuplicateVehicleClaims.length > 1)"> There are multiple claims in the Online Claims portal for this VIN submitted within the past 7 days.</span>
      <div>
        <ng-container *ngFor="let claim of e2DuplicateVehicleClaims">
          <ul class="d-inline-block my-0 ms-0 me-3 p-0">
            <li>
              <a href="https://e2.eazeetrak.com/claims/{{claim.enterpriseId}}/detail" target="_blank">{{claim.coverageTypeLabel}}
                Claim {{claim.enterpriseId}}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link ms-1" _ngcontent-ng-c4060576122=""><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
              </a>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
