import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PushToE2Service} from "./push-to-e2.service";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-push-to-e2',
  standalone: true,
  imports: [
    NgIf,
    NgClass
  ],
  templateUrl: './push-to-e2.component.html',
  styleUrl: './push-to-e2.component.scss'
})
export class PushToE2Component {
  @Input() claimId!: number;
  @Output() pushedToE2 = new EventEmitter<void>();
  pushed = false;
  isSubmitting = false;

  constructor(private pushToE2Service: PushToE2Service) {
  }

  pushToE2() {
    this.isSubmitting = true;
    this.pushToE2Service.PushToE2(this.claimId).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.pushed = true;
        this.pushedToE2.emit();
      },
      error: error => {
        this.isSubmitting = false;
        console.error(error);
      }
    });
  }

}
