<button
  class="btn btn-outline-primary btn-bg-white btn-lg position-relative lh-md w-100"
  type="button"
  (click)="pushToE2()"
>
  <span
    [ngClass]="isSubmitting ? 'show' : ''"
    class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
    role="status" aria-hidden="true"></span>
  <span [ngClass]="isSubmitting ? '' : 'show'" class="text fade">Push to E2</span>
</button>
<p *ngIf="pushed">Pushed</p>
