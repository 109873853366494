import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PushToE2Service {

  constructor(private httpClient: HttpClient) { }

  public PushToE2(claimId: number) {
    return this.httpClient.post(`${environment.apiServer}/Adjuster/Claim/PushToE2`, {claimId: claimId});
  }
}
